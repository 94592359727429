import { useState } from "react";
import { StyledMenu } from "./styledMenus";
import { Box, Button, List } from "@mui/material";
import { useAuth } from "../provider/auth_provider";
import { NavItem } from "./chatbot_navbar";
import { useLocation } from "react-router-dom";
import {
  FaceRetouchingNaturalOutlined,
  AutoGraphOutlined,
  DonutSmallOutlined,
  DocumentScannerOutlined,
  HelpCenterOutlined,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";

type PageChangeDropdownProps = {
  tool: string;
};

export default function PageChangeDropdown({ tool }: PageChangeDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { userDetails } = useAuth();
  const { pathname } = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        size="medium"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={<BuildOutlinedIcon />}
        sx={{
          color: "#444746",
          fontSize: "1rem !important",
          padding: "0.5rem 1.25rem",
          cursor: "pointer",
          marginLeft: "0.4rem",
        }}
      >
        {tool}
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <List>
          {userDetails?.scopes?.includes("people") && (
            <NavItem
              href="/people"
              label="AMA @ People"
              pathname={pathname}
              icon={<FaceRetouchingNaturalOutlined />}
              onClick={handleClose}
            />
          )}
          {userDetails?.scopes?.includes("company") && (
            <NavItem
              href="/company"
              label="AMA @ Company"
              pathname={pathname}
              icon={<AutoGraphOutlined />}
              onClick={handleClose}
            />
          )}
          {userDetails?.scopes?.includes("product") && (
            <NavItem
              href="/product"
              label="AMA @ Product"
              pathname={pathname}
              icon={<DonutSmallOutlined />}
              onClick={handleClose}
            />
          )}
          {userDetails?.scopes?.includes("document") && (
            <NavItem
              href="/document"
              label="AMA @ Document"
              pathname={pathname}
              icon={<DocumentScannerOutlined />}
              onClick={handleClose}
            />
          )}
          {userDetails?.scopes?.includes("help") && (
            <NavItem
              href="/help"
              label="AMA @ Help"
              pathname={pathname}
              icon={<HelpCenterOutlined />}
              onClick={handleClose}
            />
          )}
        </List>
      </StyledMenu>
    </Box>
  );
}
