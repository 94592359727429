import {
  MicOffOutlined,
  MicOutlined,
  Send,
  Menu,
  FaceRetouchingNaturalOutlined,
  AutoGraphOutlined,
  DonutSmallOutlined,
  DocumentScannerOutlined,
  HelpCenterOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { defaultLocale } from "../../config";
import logo from "../../assets/HDFC-Ergo-logo.png";
import Conversation from "../conversation";
import Feedback from "../feedback";
import CompetitorSuggestionChips from "./competitor_suggestions";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { NavItem } from "../chatbot_navbar";
import { useLocation } from "react-router-dom";
import { StyledMenu } from "../styledMenus";
import Profile from "../profile";
import { useAuth } from "../../provider/auth_provider";
import FeedbackIcon from "@mui/icons-material/Feedback";
import FeedbackFormModal from "../FeedbackFormModal";
import { IConversation } from "../../types/chatbot";
import PageChangeDropdown from "../PageChangeDropdown";
import useCompetitor from "../../provider/competitor_provider";
import CompetitorNewChatGreetings from "./competitor_new_chat_greetings";

let userActivityTimeout: NodeJS.Timeout | null = null;

export default function CompetitorChat({
  tool,
  vectorName,
  isDrawerOpen,
  handleDrawer,
  handleOpen,
}: {
  tool: string;
  vectorName: string;
  isDrawerOpen: boolean;
  handleDrawer: (action: boolean) => void;
  handleOpen: () => void;
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedLocale] = useState(defaultLocale);
  const [query, setQuery] = useState("");
  const [isUserInactive, setUserInactive] = useState<boolean>(false);
  const [showFeedbackCards, setShowFeedbackCards] = useState<boolean>(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const {
    chats,
    suggestions,
    selectedChat,
    addConversation,
    conversationsContainerRef,
    like,
    dislike,
    isChatLoading,
  } = useCompetitor();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { transcript, listening } = useSpeechRecognition();
  const { userDetails, source } = useAuth();
  const { pathname } = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let q = `${query}${query.length === 0 ? "" : " "}${transcript}`;
    setQuery(q);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcript]);

  useEffect(() => {
    window.addEventListener("keydown", () => {
      resetActivityCounter();
    });

    return () => {
      if (userActivityTimeout) {
        clearTimeout(userActivityTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (selectedSuggestion) {
  //     setQuery(selectedSuggestion);
  //   }
  // }, [selectedSuggestion]);

  useEffect(() => {
    resetActivityCounter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chats]);

  function resetActivityCounter() {
    if (tool !== "AI Assistant") return;
    if (userActivityTimeout) {
      clearTimeout(userActivityTimeout);
      if (isUserInactive === true) {
        setUserInactive(false);
      }
    }
    userActivityTimeout = setTimeout(() => {
      setUserInactive(true);
      if (
        chats[selectedChat].conversations.length > 0 &&
        showFeedbackCards === false
      ) {
        setShowFeedbackCards(true);
      }
    }, 20000);
  }

  useEffect(() => {
    setQuery("");
  }, [selectedChat]);

  const toolName = useMemo(() => {
    return pathname.split("/")[1];
  }, [pathname]);

  useEffect(() => {
    if (!chats?.[selectedChat]?.conversations?.length)
      return setShowFeedbackModal(false);
    const allConversations = chats.reduce(
      (acc: IConversation[], current) =>
        acc.concat(current?.conversations || []),
      []
    );
    if (
      allConversations.length % 3 === 0 &&
      !allConversations[allConversations.length - 1]?.isLoading
    ) {
      if (toolName === "people") {
        if (localStorage.getItem("peopleFeedbackSubmitted") !== "true") {
          setShowFeedbackModal(true);
        }
      } else if (toolName === "company") {
        if (localStorage.getItem("companyFeedbackSubmitted") !== "true") {
          setShowFeedbackModal(true);
        }
      } else if (toolName === "product") {
        if (localStorage.getItem("productFeedbackSubmitted") !== "true") {
          setShowFeedbackModal(true);
        }
      } else if (toolName === "document") {
        if (localStorage.getItem("documentFeedbackSubmitted") !== "true") {
          setShowFeedbackModal(true);
        }
      } else if (toolName === "help") {
        if (localStorage.getItem("helpFeedbackSubmitted") !== "true") {
          setShowFeedbackModal(true);
        }
      }
    }
  }, [chats, selectedChat, toolName]);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      height="100svh"
      // flex={1}
      position={"relative"}
      overflow={"hidden"}
      gap={1}
    >
      {!isSmallScreen && !source && (
        <Box
          className="nav-tool"
          display="flex"
          maxWidth={"calc(100vw - 17.5rem)"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <PageChangeDropdown tool={tool} />

          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ px: 1, gap: 0.5 }}
          >
            <Button
              onClick={() => setShowFeedbackModal(true)}
              sx={{ minWidth: "auto", px: 1 }}
            >
              <FeedbackIcon />
            </Button>
            <Profile />
          </Box>
        </Box>
      )}

      {showFeedbackModal && (
        <FeedbackFormModal
          show={showFeedbackModal}
          closeModal={() => setShowFeedbackModal(false)}
        />
      )}

      {isSmallScreen ? (
        <Box
          position={"absolute"}
          width={"100%"}
          // height={60}
          sx={{
            top: 0,
            margin: "auto 0",
            zIndex: 9999,
            backgroundColor: "white",
            // background:
            //   "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2))",
            // backdropFilter: "blur(2.5rem)",
            width: "100%",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={2}
          >
            <Box display="flex" alignItems="center" gap={1.6}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => handleDrawer(true)}
                sx={[{ ml: 1, p: 0 }, isDrawerOpen && { display: "none" }]}
              >
                <Menu
                  sx={{
                    fontSize: 26,
                    color: "#000000b5",
                    marginLeft: "-0.4rem",
                  }}
                />
              </IconButton>
              <a
                href={!source ? "/dashboard" : "/"}
                style={{ textDecoration: "none", color: "inherit", height: 40 }}
              >
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: 40, height: 40, objectFit: "contain" }}
                />
              </a>
            </Box>
            {!source && (
              <>
                <Box sx={{ flex: 1, pl: 2 }}>
                  <Divider orientation="vertical" />
                  <Button
                    size="medium"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    sx={{ color: "#444746", fontSize: "0.85rem !important" }}
                  >
                    {tool}
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <List>
                      {userDetails?.scopes?.includes("people") && (
                        <NavItem
                          href="/people"
                          label="AMA @ People"
                          pathname={pathname}
                          icon={<FaceRetouchingNaturalOutlined />}
                          onClick={handleClose}
                        />
                      )}

                      {userDetails?.scopes?.includes("company") && (
                        <NavItem
                          href="/company"
                          label="AMA @ Company"
                          pathname={pathname}
                          icon={<AutoGraphOutlined />}
                          onClick={handleClose}
                        />
                      )}

                      {userDetails?.scopes?.includes("product") && (
                        <NavItem
                          href="/product"
                          label="AMA @ Product"
                          pathname={pathname}
                          icon={<DonutSmallOutlined />}
                          onClick={handleClose}
                        />
                      )}

                      {userDetails?.scopes?.includes("document") && (
                        <NavItem
                          href="/document"
                          label="AMA @ Document"
                          pathname={pathname}
                          icon={<DocumentScannerOutlined />}
                          onClick={handleClose}
                        />
                      )}

                      {userDetails?.scopes?.includes("help") && (
                        <NavItem
                          href="/help"
                          label="AMA @ Help"
                          pathname={pathname}
                          icon={<HelpCenterOutlined />}
                          onClick={handleClose}
                        />
                      )}
                    </List>
                  </StyledMenu>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  sx={{ px: 1, gap: 0.5 }}
                >
                  <Button
                    onClick={() => setShowFeedbackModal(true)}
                    sx={{ minWidth: "auto", px: 1 }}
                  >
                    <FeedbackIcon />
                  </Button>
                  <Profile />
                </Box>
              </>
            )}
          </Box>
          <Divider />
        </Box>
      ) : null}
      <Box
        flex={1}
        pt={4}
        sx={{ scrollBehavior: "smooth", overflowY: "auto" }}
        ref={conversationsContainerRef}
      >
        <Box height={"100%"} display="flex" flexDirection="column">
          {/* {selectedQuery === null && */}
          {chats?.[selectedChat]?.conversations?.length === 0 ? (
            <CompetitorNewChatGreetings
              suggestions={suggestions}
              tool={tool}
              vectorName={vectorName}
            />
          ) : (
            <Grid
              container
              // spacing={isSmallScreen ? 0 : 2}
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
            >
              <Grid
                item
                md={8}
                sm={12}
                xs={12}
                sx={{
                  marginTop: isSmallScreen ? "2.5rem" : "0",
                  minHeight: "calc(100% - 4.5rem)",
                }}
              >
                {/* {(selectedQuery !== null ? last5Queries : chats)?.[
                    selectedQuery !== null ? selectedQuery : selectedChat
                  ]?.conversations?.map((conversation: any, index: number) => { */}
                {chats?.[selectedChat]?.conversations?.map(
                  (conversation: any, index: number) => {
                    return (
                      <Conversation
                        key={index}
                        like={() => like(index)}
                        dislike={() => dislike(index)}
                        conversation={conversation}
                        tool={tool}
                      />
                    );
                  }
                )}
              </Grid>
              {/* {selectedQuery === null && */}
              {suggestions ? (
                <Grid item md={8} sm={12} xs={12}>
                  <Box>
                    <CompetitorSuggestionChips
                      suggestions={
                        suggestions || []
                      }
                      gridSpace={4}
                      align={"flex-end"}
                      vectorName={vectorName}
                    />
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          )}
        </Box>
      </Box>
      <Box pb={1} display={"absolute"} bottom={0} left={0}>
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {showFeedbackCards && (
            <Grid item md={8} sm={12} xs={12}>
              <Box>
                <Grid container spacing={2} alignItems={"flex-end"}>
                  <Grid item>
                    <Feedback />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}

          <Grid item md={8} sm={12} xs={12}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (query.length > 0 && !isChatLoading) {
                  addConversation(query, selectedLocale, vectorName);
                  setQuery("");
                }
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                px={isSmallScreen ? 2 : 0}
              >
                <Box flex={1}>
                  <Box
                    p={1}
                    px={2.5}
                    bgcolor={"white"}
                    borderRadius={4}
                    boxShadow={10}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <TextField
                      placeholder={`Enter a prompt here`}
                      autoComplete="off"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      type="text"
                      name="chatbot-query"
                      fullWidth
                      variant="standard"
                      sx={{ borderRadius: 20, zIndex: 9999 }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          borderRadius: "0.625rem",
                          fontSize: "0.8125rem",
                        },
                      }}
                    />

                    {!isSmallScreen ? (
                      <IconButton
                        size="small"
                        onClick={() =>
                          listening
                            ? SpeechRecognition.stopListening()
                            : SpeechRecognition.startListening()
                        }
                      >
                        {listening ? <MicOffOutlined /> : <MicOutlined />}
                      </IconButton>
                    ) : null}
                    <span style={{ width: "0.25rem" }}></span>
                    <IconButton size="small" type="submit">
                      <Send />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Box mt={1} sx={{ display: "grid", placeItems: "center" }}>
                <Typography
                  textAlign="center"
                  fontSize={12}
                  color="#4d4848"
                  fontWeight={500}
                >
                  Note: Always verify the generated response
                </Typography>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
