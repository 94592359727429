// import FancyText from "@carefully-coded/react-text-gradient";
import {
  // Alert,
  // AlertTitle,
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import SuggestionChips from "./competitor_suggestions";
import { motion } from "framer-motion";
import { getFirstName } from "../../utils/common";
import { useAuth } from "../../provider/auth_provider";

export default function CompetitorNewChatGreetings({
  suggestions,
  tool,
  vectorName,
}: {
  suggestions: string[];
  tool: string;
  vectorName: string;
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { userDetails } = useAuth();

  theme.typography.h1 = {
    fontSize: "4rem",
    textWrap: "nowrap",

    "@media (min-width:37.5rem)": {
      fontSize: "4rem",
      textWrap: "nowrap",
    },

    [theme.breakpoints.up("md")]: {
      fontSize: "3.2rem",
      textWrap: "nowrap",
    },
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={"center"}
      alignItems="center"
      px={2}
      mt={6}
      sx={{ width: "100", cursor: "default" }}
    >
      <Grid container justifyContent={"center"} alignItems={"flex-start"}>
        <Grid item md={8}>
          <motion.div
            initial={{ y: 100 }}
            transition={{ duration: 0.75 }}
            whileInView={{ y: 0 }}
            style={{ zIndex: -1 }}
          >
            <Typography
              variant={isSmallScreen ? "h4" : "h3"}
              fontWeight={"600"}
              mt={4}
              sx={{ color: "#444746", fontSize: 24 }}
            >
              Hello, {getFirstName(userDetails?.name as string)}!
            </Typography>
          </motion.div>
          <motion.div
            initial={{ y: 100 }}
            transition={{ duration: 1 }}
            whileInView={{ y: 0 }}
            style={{ zIndex: -1, textWrap: "nowrap" }}
          >
            <Typography
              variant={isSmallScreen ? "h6" : "h5"}
              color={grey[500]}
              sx={{ fontSize: 16 }}
            >
              Ask me anything!
            </Typography>
          </motion.div>

          <Box mt={4}>
            <SuggestionChips
              suggestions={suggestions}
              gridSpace={4}
              vectorName={vectorName}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
