import { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import ManageHelpModal from "../components/manage-help-modal";
import CompetitorNavbar from "../components/competitor-new/competitor_navbar";
import CompetitorChat from "../components/competitor-new/competitor_chat";

export default function Competitor({ tool }: { tool: string }) {
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const location = useLocation();

  const handleOpen = (pathname: string) => {
    setOpenHelpModal(true);
  };
  const handleClose = () => {
    setOpenHelpModal(false);
  };

  const handleDrawer = useCallback((action: boolean) => {
    setIsDrawerOpen(action);
  }, []);

  useEffect(() => {
    let pathname = location.pathname;
    if (pathname === "/document" || pathname === "/help") {
      handleOpen(pathname);
    } else {
      handleClose();
    }
  }, [location.pathname]);

  return (
    <div className="App">
      <Box display="flex" height="100svh">
        <CompetitorNavbar
          tool={tool}
          vectorName={""}
          handleOpen={handleOpen}
          isDrawerOpen={isDrawerOpen}
          handleDrawer={handleDrawer}
        />
        <Box flex={1} bgcolor={"#f6f9f8"}>
          <CompetitorChat
            vectorName={""}
            tool={tool}
            isDrawerOpen={isDrawerOpen}
            handleDrawer={handleDrawer}
            handleOpen={() => handleOpen(location.pathname)}
          />
        </Box>

        <Modal
          open={openHelpModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "auto",
              maxWidth: "95vw",
              maxHeight: "70vh",
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: 24,
              p: 2.5,
              pb: 2,
              overflow: "hidden",
              overflowY: "auto",
              display: "block",
            }}
          >
            <ManageHelpModal handleClose={handleClose} />
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
