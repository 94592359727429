import axios from "axios";

type CompetitorPayload = {
  conversation: {
    role: string;
    content: string;
  }[];
  query: string;
};


type CompetitorSuggestionsPayload = {
  query: string;
};


type CompetitorFeedbackPayload = {
  questionId: number;
  request: string;
  response: string;
  feedBack: boolean;
  displayType: string;
}

type competitorGetChatHistoryPayload = {
  service_name: string;
  userID: number;
}

export const competitorChatbot = async (payload: CompetitorPayload) => {
  const response = await axios.post(
    process.env.REACT_APP_BASE_URL + "/user-message",
    payload
  );
  return response.data;
};

export const competitorSuggestions = async (payload: CompetitorSuggestionsPayload) => {
  const response = await axios.post(
    process.env.REACT_APP_BASE_URL + "/suggestion",
    payload
  );
  return response.data;
}

export const competitorFeedback = async (payload: CompetitorFeedbackPayload) => {
  const response = await axios.post(
    process.env.REACT_APP_BASE_URL + "/feedback",
    payload
  );
  return response.data;
}

export const competitorGetChatHistory = async (payload: competitorGetChatHistoryPayload) => {
  const response = await axios.post(
    process.env.REACT_APP_BASE_URL + "/get_chat_history",
    payload
  );
  return response.data
}